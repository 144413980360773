import React from 'react'
import SectionTitle from '../components/Ui/SectionTitle'
import { graphql, Link } from 'gatsby'
import Gallery from '../components/Gallery'
import Playlist from '../components/Playlist'
import { StaticImage } from 'gatsby-plugin-image'
import Banner from '../components/NewTheme/Ui/Banner'
import Wolflayout from '../components/wolflayout'

const PhotosVideosPage = ({ data, location }) => {
  const photoGallery = data.photoGallery
  const youtubePlaylist = data.youtubePlaylist
  return (
    <Wolflayout>
      <Banner
        title="Photos &"
        titleLine2="Vidéos"
        />
      <div className="flex justify-center items-center mt-12 text-white">
        <div className="w-1/2 max-w-130 mr-5">
          <Link
            to="#photos"
            className="group flex flex-col justify-center items-center font-tradeGothic text-2xl font-bold uppercase"
          >
            <StaticImage
              src="../images/icon-photo.png"
              alt="icone d'un appareil photo"
              className="mb-5 transition-all group-hover:rotate-12 brightness-0 invert"
            />
            <span className="text-white no-underline after:border-b-white after:border-b-2 after:absolute after:bottom-0 after:left-0 after:w-full after:scale-x-0 after:transition-all group-hover:after:scale-x-100 inline-block relative">
              Photos
            </span>
          </Link>
        </div>
        <div className="w-1/2 max-w-130">
          <Link
            to="#videos"
            className="group flex flex-col justify-center items-center font-tradeGothic text-2xl font-bold uppercase"
          >
            <StaticImage
              src="../images/icon-video.png"
              alt="icone d'un clap de cinema"
              className="mb-5 transition-all group-hover:rotate-12 brightness-0 invert"
            />
            <span className="text-white after:border-b-white after:border-b-2 after:absolute after:bottom-0 after:left-0 after:w-full after:scale-x-0 after:transition-all group-hover:after:scale-x-100 inline-block relative">
              Vidéos
            </span>
          </Link>
        </div>
      </div>
      <div className="container photos-videos pb-24">
        <span className="relative -translate-y-24 block" id="photos" />
        <section className={'font-heading text-white py-24 sm:-mx-4'}>
          <SectionTitle className={'!text-white'}>Photos</SectionTitle>
          <div className={'flex flex-wrap'}>
              {photoGallery.edges &&
                  photoGallery.edges.map(({ node }, i) => {
                    const { gallery_title: galleryTitle, gallery_link: galleryLink, gallery_images: galleryImages } =
                          node.data
                    return (
                          <Gallery
                              key={i}
                              title={galleryTitle.text}
                              images={galleryImages}
                              button={{
                                url: galleryLink.url
                              }}
                          />
                    )
                  })}
          </div>
        </section>
        <span className="relative -translate-y-40 block" id="videos" />
        <section className={'font-heading text-white sm:-mx-5'}>
          <SectionTitle className={'!text-white'}>Vidéos</SectionTitle>
          <div>
              {youtubePlaylist.edges &&
                  youtubePlaylist.edges.map(({ node }, i) => {
                    const { playlist_title: playlistTitle, playlist_link: playlistLink, youtube_videos: youtubeVideos } =
                          node.data
                    return (
                          <Playlist
                              key={i}
                              title={playlistTitle.text}
                              button={{
                                url: playlistLink.url
                              }}
                              videos={youtubeVideos}
                          />
                    )
                  })}
          </div>
        </section>
      </div>
    </Wolflayout>
  )
}

export const query = graphql`
  query {
    photoGallery: allPrismicPhotoGallery {
      edges {
        node {
          data {
            gallery_title {
              text
            }
            gallery_link {
              url
              target
            }
            gallery_images {
              gallery_image {
                gatsbyImageData
                alt
              }
            }
          }
        }
      }
    }
    youtubePlaylist: allPrismicYoutubePlaylist {
      edges {
        node {
          data {
            youtube_videos {
              video_title {
                text
              }
            }
            playlist_title {
              text
            }
            playlist_link {
              url
              target
            }
          }
        }
      }
    }
  }
`

export default PhotosVideosPage
